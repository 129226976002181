import Vue from 'vue'
import App from './App.vue'
import router from '@/router'                 // api: https://github.com/vuejs/vue-router
import store from '@/store'                   // api: https://github.com/vuejs/vuex
import VueCookie from 'vue-cookie'            // api: https://github.com/alfhen/vue-cookie
import '@/element-ui'
import '@/element-ui-theme'
import httpRequest from '@/utils/httpRequest'

Vue.use(VueCookie)
Vue.config.productionTip = false
Vue.prototype.$http = httpRequest

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App },
  render: h => h(App),
})
