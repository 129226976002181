<template>
  <el-dialog
    :title="'管理员信息'"
    :close-on-click-modal="false"
    width="400px"
    @close="close()"
    :visible.sync="visible">
    <div class="consult-main-body">
      <div class="consult-top">
        <div class="consult-top-content">
          <img :src="imgUrl" class="el-image__inner" />
        </div>
      </div>
      <div class="consult-bottom" style="margin-top: 0">
        <p>管理员微信</p>
      </div>
      <div class="consult-bottom">
        <p>联系邮箱地址：{{dataForm.email}}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close()">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          filename: '',
          originalFilename: '',
          email: '',
          imageCode: ''
        },
        imgUrl: ''
      }
    },
    methods: {
      init () {
        this.visible = true
        this.getSpRoleList()
      },
      getSpRoleList () {
        this.$http({
          url: this.$http.adornUrl('/myshop/spmainconsult/getConsultInfo'),
          method: 'get',
          headers: {'fromUser': '1'},
          params: this.$http.adornParams({
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm.id = data.data.id
            this.dataForm.filename = data.data.filename
            this.dataForm.originalFilename = data.data.originalFilename
            this.dataForm.email = data.data.email
            this.dataForm.imageCode = data.data.imageCode
            this.imgUrl = this.$http.adornUrl(`/myshop/spgoods/returnStream?token=test&filename=` + this.dataForm.filename)
            console.log(this.imgUrl)
          }
        })
      },
      close () {
        this.visible = false
      }
    }
  }
</script>
<style scoped>
.consult-main-body {
  width: 100%;
  height: 100%;
}
.consult-top {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.consult-top-content, .el-image__inner {
  width: 200px;
  height: 200px;
}
.consult-bottom {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  font-size: 15px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
</style>