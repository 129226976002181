<template>
  <div class="mod-home">
    <div class="mod-home-content">
      <div class="mod-level1">
        <div class="mod-level1-body">
          <div class="mod-level1-info">
          </div>
          <div class="mod-level1-title">
            <p>欧洲严选速通骨干网</p>
          </div>
          <div class="mod-level1-b2b">
            <div class="mod-level1-btn" @click="gotoB2B()">
              <p>进入B2B平台</p>
            </div>
            <div class="mod-level1-btn1" @click="consultBtn()">
              <p>入驻咨询</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mod-level2">
        <p>我们的平台</p>
      </div>
      <div class="mod-level3">
        <div class="mod-level3-body">
          <div class="mod-level3-body-contain">
            <div class="mod-level3-body-top">
              <div class="mod-level3-body-top-title">
                <p>B2B平台</p>
              </div>
              <div class="mod-level3-body-top-info">
                <p>欧众云仓，您的欧洲业务加速器。我们以用户为中心的数字平台，提供一站式服务，从轻松浏览到便捷支付，再到高效物流，打造无缝的端到端体验。我们的全球网络，将全球供应商与经销商紧密相连，构建了一个协同发展的商业生态，助力企业茁壮成长。</p>
              </div>
            </div>
            <div class="mod-level3-body-bottom">
              <div class="mod-level3-body-bottom-content">
                <div class="mod-level3-body-bottom-top">
                  <div class="mod-level3-body-bottom-top-left">
                    <div class="mod-level3-top-left-img">
                    </div>
                  </div>
                  <div class="mod-level3-body-bottom-top-right">
                    <div class="mod-level3-top-left-content">
                      <div class="top-left-content-top">
                        <p>供应商</p>
                      </div>
                      <div class="top-left-content-bottom">
                        <div class="top-left-content-bottom-content">
                          <p>欧洲业务的创新引擎。品牌商与制造商汇聚于此，通过我们的全球经销商网络，迅速提升业绩巅峰。我们提供先进的营销工具、数据驱动的运营策略和全面的物流解决方案，赋予您对库存的绝对掌控。加入我们的社区，与广泛的买家网络建立紧密联系，简化您的业务流程，实现业务增长和盈利的最大化。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mod-level3-body-bottom-bottom">
                  <div class="mod-level3-body-bottom-bottom-left">
                    <div class="mod-level3-bottom-left-content">
                      <div class="top-left-content-top">
                        <p>经销商</p>
                      </div>
                      <div class="top-left-content-bottom">
                        <div class="top-left-content-bottom-content">
                          <p>经销商们可以轻松融入高效技术贸易网，一触即达全球商品，省去跨国采购之劳。我们以全国覆盖、多元交易、广阔仓储，助力简化运营，优化供应链。一键体验，尽掌便利、可靠、灵活之美，尽在指尖。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mod-level3-body-bottom-bottom-right">
                    <div class="mod-level3-bottom-right-img">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mod-level4">
        <p>我们的物流</p>
      </div>
      <div class="mod-level5">
        <div class="mod-level5-content">
          <div class="mod-level5-left">
            <div class="mod-level5-show">
              <div class="mod-level5-title">
                <p>有竞争力的空运和陆运</p>
              </div>
              <div class="mod-level5-text">
                <p>我们以规模效应，为供应商大幅降低运输成本，助力经销商拓展业务，提升利润，优化交付。在激增的销售额面前，快速、经济的货运服务，是我们对您承诺的实现。</p>
              </div>
              <div class="mod-level5-img1">
              </div>
            </div>
          </div>
          <div class="mod-level5-center">
            <div class="mod-level5-show">
              <div class="mod-level5-title">
                <p>综合仓储</p>
              </div>
              <div class="mod-level5-text">
                <p>欧众云仓，俄罗斯核心，仓储网络覆盖欧洲各区。我们的仓库专为大件易损件商品设计，优化存储效率，确保买家和卖家享受最具竞争力的存储成本。加入我们，体验综合仓储的便捷与经济。</p>
              </div>
              <div class="mod-level5-img2">
              </div>
            </div>
          </div>
          <div class="mod-level5-right">
            <div class="mod-level5-show">
              <div class="mod-level5-title">
                <p>可靠的交付</p>
              </div>
              <div class="mod-level5-text">
                <p>在大数据和人工智能的驱动下，我们的数据湖实时更新，为您提供精心严选的产品。享受一键送仓的便捷，省去繁琐流程，包括一键约仓、全流程入仓安排，以及欧洲范围内的快速履约服务。上门取送件服务，让您的物流体验更加个性化、高效。选择我们，享受从选择到配送的无缝、无忧物流体验。</p>
              </div>
              <div class="mod-level5-img3">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mod-level6">
      </div>
      <div class="mod-level7">
        <div class="mod-level7-content">
          <div class="mod-level7-content-left">
            <div class="level7-left-1">
              <div class="level7-left-1-div">
                <div class="level7-left-item-icon">
                  <img src="./assets/images/call.png" class="el-image__inner level7-img" />
                </div>
              </div>
              <div class="level7-left-1-text">
                <span>电话(中国区): 13822746459</span>
              </div>
            </div>
            <div class="level7-left-1">
              <div class="level7-left-1-div">
                <div class="level7-left-item-icon">
                  <img src="./assets/images/mail.png" class="el-image__inner level7-img" />
                </div>
              </div>
              <div class="level7-left-1-text">
                <span>商业合作邮箱: alfredALLZONEzi1@163.com-Berelax</span>
              </div>
            </div>
            <div class="level7-left-1">
              <div class="level7-left-1-div">
                <div class="level7-left-item-icon">
                  <img src="./assets/images/mail.png" class="el-image__inner level7-img" />
                </div>
              </div>
              <div class="level7-left-1-text">
                <span>(俄罗斯)邮箱: alfredzhouAllzone1@yandex.com</span>
              </div>
            </div>
            <div class="level7-left-1">
              <div class="level7-left-1-div">
                <div class="level7-left-item-icon">
                  <img src="./assets/images/loc.png" class="el-image__inner level7-img" />
                </div>
              </div>
              <div class="level7-left-1-text">
                <span>加公司信息公司(中国区)地址: 广州市白云区大胜创意园1期</span>
              </div>
            </div>
            <div class="level7-left-1">
              <div class="level7-left-1-div">
                <div class="level7-left-item-icon">
                  <img src="./assets/images/loc.png" class="el-image__inner level7-img" />
                </div>
              </div>
              <div class="level7-left-1-text">
                <span>(俄罗斯)地址: Московская обл., Домодедово, с101, 918456 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mod-level8">
        <p>@欧众（广州）跨境电商有限公司</p>
      </div>
    </div>
    <user-consult v-if="userConsultVisible" ref="userConsult" @refreshDataList="getDataList"></user-consult>
  </div>
</template>

<script>
import UserConsult from './user-consult.vue'
export default {
  mounted() {
    // 设置页面标题
    document.title = '供应链销售系统';
  },
  created() {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    console.log(this.isMobile);
  },
  data () {
    return {
      userConsultVisible: false,
      isMobile: false
    }
  },
  components: {
    UserConsult
  },
  methods: {
    gotoB2B () {
      // console.log(this.$router);
      // this.$router.push('/category')
      window.open('http://www.ozonb2b.com/shop/#/main', '_blank');
    },
    consultBtn () {
      this.userConsultVisible = true
      this.$nextTick(() => {
        this.$refs.userConsult.init()
      })
    },
    getDataList () {

    }
  }
}
</script>

<style scoped>
.mod-home {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .mod-home-content {
    min-width: 1200px;
    height: 100%;
  }
}
.mod-level1 {
  width: 100%;
  height: 800px;
  background-image: url(assets/images/1.jpg);
  display: flex;
  justify-content: center; /* 水平居中 */
  .mod-level1-body {
    width: 480px;
    height: 300px;
  }
}
.mod-level1-info {
  width: 480px;
  height: 170px;
  background-size: 480px 282px;
  background-image: url(assets/images/2.png);
  margin-top: -53px;
}
.mod-level1-title {
  color: #0f3059;
  font-size: 38px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 180px;
}
.mod-level1-b2b {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 100%;
  height: 100px;
}
.mod-level1-btn, .mod-level1-btn1 {
  width: 200px;
  height: 45px;
  border: 1px solid #0f3059;
  color: #0f3059;
  font-size: 17px;
  display: flex;
  cursor: pointer;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level1-btn1 {
  margin-left: 25px;
}
.mod-level1-btn:hover, .mod-level1-btn1:hover {
  background-color: #0f3059;
  color: #ffffff;
}
.mod-level2, .mod-level4 {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  width: 100%;
  height: 100px;
  background-color: #10325d;
  color: #ffffff;
  font-weight: bold;
  font-size: 40px;
}
.mod-level3 {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 100%;
  height: 900px;
}
.mod-level3-body {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 730px;
  height: 100%;
  .mod-level3-body-contain {
    width: 100%;
    height: 100%;
    .mod-level3-body-top {
      width: 100%;
      height: 250px;
    }
  }
}
.mod-level3-body-top-title {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 100%;
  height: 79px;
  font-size: 32px;
  font-weight: bold;
  line-height: 4.5rem;
  font-family: Georgia;
  color: #000000;
}
.mod-level3-body-top-info {
  width: 100%;
  height: 230px;
  font-size: 21px;
  font-family: Arial;
  line-height: 2.2rem;
}
.mod-level3-body-bottom {
  width: 100%;
  height: 442px;
  display: flex;
  justify-content: center; /* 水平居中 */
  .mod-level3-body-bottom-content {
    width: 560px;
    height: 100%;
    .mod-level3-body-bottom-top {
      width: 100%;
      height: 50%;
    }
  }
}
.mod-level3-body-bottom-top-left {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level3-top-left-img {
  width: 96%;
  height: 96%;
  background-size: 270px 290px;
  background-repeat: no-repeat;
  background-image: url(assets/images/3.png);
}
.mod-level3-top-left-content {
  width: 96%;
  height: 96%;
  background-color: #10325d;
}
.top-left-content-top {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center; /* 水平居中 */
  color: #ffffff;
  font-weight: bold;
  font-size: 30px;
}
.top-left-content-bottom {
  width: 100%;
  height: 69%;
  color: #ffffff;
  display: flex;
  justify-content: center; /* 水平居中 */
  font-size: 13px;
  .top-left-content-bottom-content {
    width: 89%;
    height: 100%;
  }
}
.mod-level3-body-bottom-bottom {
  width: 100%;
  height: 50%;
}
.mod-level3-body-bottom-bottom-right {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level3-bottom-left-content {
  width: 96%;
  height: 96%;
  background-color: #10325d;
}
.top-left-content-top {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center; /* 水平居中 */
  color: #ffffff;
  font-weight: bold;
  font-size: 30px;
}
.top-left-content-bottom {
  width: 100%;
  height: 69%;
  color: #ffffff;
  display: flex;
  justify-content: center; /* 水平居中 */
  font-size: 13px;
  .top-left-content-bottom-content {
    width: 89%;
    height: 100%;
  }
}
.mod-level3-bottom-right-img {
  width: 96%;
  height: 96%;
  background-size: 270px 290px;
  background-repeat: no-repeat;
  background-image: url(assets/images/4.png);
}
.mod-level5 {
  width: 100%;
  height: 492px;
  display: flex;
  justify-content: center; /* 水平居中 */
}
.mod-level5-content {
  width: 1180px;
  height: 442px;
  margin-top: 35px;
}
.mod-level5-left, .mod-level5-center, .mod-level5-right {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  float: left;
}
.mod-level5-show {
  width: 93%;
  height: 100%;
}
.mod-level5-title {
  width: 100%;
  height: 3%;
  font-size: 22px;
  font-weight: bold;
}
.mod-level5-text {
  width: 100%;
  height: 30%;
  color: #000;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.475rem;
  margin-bottom: 1.625rem;
  margin-top: 1.375rem;
}
.mod-level1-btn {
  width: 200px;
  height: 45px;
  border: 1px solid #0f3059;
  color: #0f3059;
  font-size: 17px;
  display: flex;
  cursor: pointer;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level1-btn1 {
  width: 200px;
  height: 45px;
  border: 1px solid #0f3059;
  color: #0f3059;
  font-size: 17px;
  display: flex;
  cursor: pointer;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level3-body-bottom-top-right {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level3-body-bottom-bottom-left {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.mod-level5-img2 {
  width: 100%;
  height: 60%;
  background-size: 360px 202px;
  background-repeat: no-repeat;
  background-image: url(assets/images/6_new.jpg);
}
.mod-level5-img1 {
  width: 100%;
  height: 60%;
  background-size: 360px 202px;
  background-repeat: no-repeat;
  background-image: url(assets/images/5_new.jpg);
}
.mod-level5-img3 {
  width: 100%;
  height: 60%;
  background-size: 360px 202px;
  background-repeat: no-repeat;
  background-image: url(assets/images/7_new.jpg);
}
.mod-level6 {
  width: 100%;
  height: 20px;
  background-color: #10325d;
}
.mod-level7 {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center; /* 水平居中 */
}
.mod-level7-content {
  width: 1180px;
  height: 280px;
  margin-top: 35px;
}
.mod-level7-content-left {
  width: 50%;
  height: 100%;
  float: left;
}
.level7-left-1 {
  width: 100%;
  height: 50px;
}
.level7-left-1-div {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  float: left;
}
.level7-left-item-icon {
  width: 30px;
  height: 30px;
  border: 1px solid #0f3059;
  background-color: #10325d;
  border-radius: 50%;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}
.level7-img {
  width: 25px;
  height: 25px;
}
.level7-left-1-text {
  width: 88%;
  height: 50px;
  float: left;
  padding-left: 1px;
  font-size: 15px;
  display: flex;
  align-items: center; /* 垂直居中 */
}
.mod-level8 {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  width: 100%;
  height: 40px;
  background-color: #10325d;
  color: #ffffff;
  font-size: 13px;
}
</style>

